import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router, RouterOutlet } from '@angular/router';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { LeaveDetailsComponent } from '../../../projects/hr/src/app/leaves/leave-details/leave-details.component';
import {ComponentloaderService} from './../../shared/componentloader.service';
@Component({
  selector: 'app-notficationsnack',
  standalone: true,
  imports: [
    CommonModule, SharedModule
  ],

  templateUrl: './notificationsnack.html',
  styleUrl: './notficationsnack.component.css'
})
export class NotficationsnackComponent { 
  public recordDetails:any;
  public records_loaded=0;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,public router:Router, private _snackRef: MatSnackBarRef<NotficationsnackComponent>,public cls:ComponentloaderService) {
    //(this.data,'notification data')

   }
   openNotification(){
    this.data.preClose(1);
   }
   closeNotification(){
    this.data.preClose(0);
   
   }
 
  Leaveformclose(type:any){
    this._snackRef.dismiss();
  }
}
