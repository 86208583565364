<!-- <ng-container *ngIf="records_loaded==0"> -->
<div class="row d-flex mx-0 p-0 align-items-center">
    <div class="col-12 p-0 m-0">
        <ng-container *ngIf="data.note_details!==null">
        <div class="row mx-0 p-0 d-flex align-items-center justify-content-between">
            <div class="row m-0 p-0 d-flex d-flex mx-0 align-items-center mb-2">
                <div class="col-auto ps-0 ">
                <img [src]="data['mediaUrl']+'profile/'+data.note_details[7]" alt="image"
                    class="userprofile-img rounded-circle" alt="" onerror="this.onerror=null;this.src='./assets/images/onerror-img.png';"> 
                </div>
                <div class="col-auto p-0 ">
                  <p class="fw-normal text-capitalize "><label>{{data.note_details[4]}}
                    </label> <small> ({{data.note_details[5]}}) <br> {{data.note_details[6]}}</small>
                  </p>
                </div>
            </div>
            <div class="row m-0 pt-2 px-0 d-flex d-flex mx-0 align-items-center justify-content-between border-t-1 ">
            <div class="col-auto p-1 text-primary d-inline-flex align-items-center" style="background:#fff">
               <ng-container *ngIf="data.note_details[0]"><h3 class="mb-0">#{{data.note_details[8]=='leaves'?'LE':data.note_details[8]=='travel'?'TR':data.note_details[8]=='transfers'?'Transfer':''}}{{data.note_details[0]}}</h3></ng-container>
            </div>
            <div class="col-auto p-0  d-inline-flex align-items-center">
             <span class="mx-1 fw-bold">{{data.note_details[2]}}</span> <p [style.background-color]="'#'+data.note_details[3]" class="circle-dot"></p>
            </div>
            <div class="col-12 py-2 p-0 border-b-1 mt-2">
                <div [innerHTML]="data.note_details[1]" class=" notifyscroll "></div>
            </div>
            </div>
        </div>
        </ng-container>
        <div class="row d-flex m-0 p-0 align-items-center justify-content-center mt-2">
            <div class="col px-0 text-start">
                <button mat-raised-button color="primary" type="button" (click)="openNotification()"><span
                        class="mdi mdi-eye-circle-outline"></span> Open</button>
            </div>
            <div class="col px-0 text-end">
                <button mat--stroked-button color="accent" type="button" (click)="closeNotification()"><span
                        class="mdi mdi-close-circle-outline"></span> Close</button>
            </div>
        </div>
    </div>
</div>
<!-- </ng-container> -->
