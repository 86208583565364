import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComponentloaderService {

  constructor() { }

  // declare and initialize the quote property
  // which will be a BehaviorSubject
  recordDetails = new BehaviorSubject({});

  // expose the BehaviorSubject as an Observable
  currentRecord = this.recordDetails.asObservable();

  // function to update the value of the BehaviorSubject
  updateDetails(data: any){
   // console.log("updating newxt with detials",data);
    this.recordDetails.next(data);
}
}