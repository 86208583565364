import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './common.service';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, UrlTree, CanActivateFn } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
export const canActivateTeam: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  let platformId = inject(PLATFORM_ID);
  let existSession: any = {};
  let router = inject(Router);
  const triggeredUrl = state.url;  // This is the URL that triggered the route activation
  if (isPlatformBrowser(platformId)) {
    existSession = localStorage.getItem('smarte2e_smato');
  }

  if (existSession == null || existSession == '{}' || existSession == undefined) {
    if (triggeredUrl == '/' || triggeredUrl == '/account') {
      return true;
    }else{
      return false;
    }
   // console.log("in ifff");
  //  return false;
  }
  else {
    if (triggeredUrl == '/' || triggeredUrl == '/account') {
    //  console.log("in else iff");
      router.navigate(["/dash"]);
      return false;
    } else {
    //  console.log("in else else");
      return true;
    }
  }
}

